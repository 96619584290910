import * as React from "react"
import {Grid} from "@material-ui/core";
import MainMenu from "../MainMenu";
import "./../../styles/index.scss"
import Seo from "../seo";
import "react-datepicker/dist/react-datepicker.css";
import '../../styles/contact.scss';
import '../../styles/general.scss';
import ContactBox from "../ContactBox";
import AppFooter from "../AppFooter";
import {Mixpanel} from "../Mixpanel";
import {useEffect, useMemo} from "react";
import OpenChat from "../OpenChat";
import Banner from "../Banner";
import splitContactPrismic from "../../lib/structure_data";

export default function ComponentContact({data}) {
    const prismicContact = data.prismicContact

    useEffect(() => {
        Mixpanel.track('Page-Contact');
    }, [data])

    const {
        phoneNumbers,
        emails,
        locations,
        whatsups,
        programs
    } = splitContactPrismic(prismicContact)

    let descrition = "Adrese: "
    locations.items.map(location => {
        descrition = descrition + ' ' + location.address.text + ", "
    })

    descrition = descrition + " Telefoane: "
    phoneNumbers.items.map(phoneData => {
      descrition  = descrition + " " + phoneData.phone_number.text + ", "
    })

    const seoData = {
        canonical: "https://www.cabinet-psiho.ro/contact",
        title: 'Contact - Cabinet psihologic sector 6',
        description: 'Cabinet psihologic ' + descrition,
        url: "https://www.cabinet-psiho.ro/contact/",
        metas: [
        ]
    }

    const renderUsingMemo = () => (
        <div>
            <Seo title="Contact" seoData={seoData}/>
            <Grid container>
                <MainMenu pageName={"contact"} prismicContact={prismicContact}/>

                <Banner />

                <Grid item sm={12} xl={12} lg={12} md={12} className={`w-full  flex flex-row justify-content-center align-middle align-items-center`} >
                    <div className={"w-full paper"}>
                        <div className={'flex flex-row justify-content-center align-middle align-items-center flex-wrap'}>
                            <div className={"heading_1 primary_heading_1_title color_green_light font-bold text_green_light_color font_size_xl"}>
                                Contact
                            </div>
                            <div className={"color_green_light font-bold text_green_dark_color font_size_xl"}>
                                {prismicContact.data.second_title.text}
                            </div>
                        </div>

                        <div className={'flex flex-row justify-content-center align-middle align-items-center flex-wrap'}>
                            {prismicContact.data.first_meeting_is_free && (
                                <div className={"flex flex-row justify-content-center align-middle align-items-center mb-8 flex-wrap"}>
                                    <div className={"color_green_light font-bold text_green_light_color font_size_l mr-4"} >
                                        <i>{prismicContact.data.text_first_meeting_free.text}</i>
                                    </div>
                                    <div className={"font-bold text_green_dark_color font_size_l"}>
                                        <OpenChat />
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className={'flex flex-row justify-content-center align-middle align-items-center flex-wrap'}>
                            <ContactBox prismicContact={prismicContact}/>
                        </div>
                    </div>
                </Grid>

                <AppFooter prismicContact={prismicContact}/>
            </Grid>
        </div>
    )

    const memoizedValue = useMemo(() => renderUsingMemo(), [prismicContact]);
    return (<>{memoizedValue}</>)
}


